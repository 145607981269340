<template>
  <!-- ユーザ管理 -->
  <b-container class="py-4">
    <h1>ユーザ管理</h1>
    <section id="user-list" class="mb-5">
      <!-- ユーザ一覧 -->
      <b-card no-body>
        <b-card-body>
          <b-card-title title-tag="h2"> ユーザ一覧 </b-card-title>
          <b-form-row>
            <b-col cols="6">
              <b-input-group class="mb-3">
                <b-input-group-prepend is-text>
                  <b-icon icon="filter" variant="csub"></b-icon>
                </b-input-group-prepend>
                <b-form-input
                  v-model="searchText"
                  placeholder="Filter"
                ></b-form-input>
              </b-input-group>
            </b-col>
            <b-col cols="6" offset-sm="2" sm="4" offset-lg="4" lg="2">
              <b-button
                variant="caccent"
                class="w-100 mb-3"
                v-on:click="openAddForm()"
              >
                新規登録
              </b-button>
            </b-col>
          </b-form-row>
          <b-table
            v-bind:fields="userListFields"
            v-bind:items="userList"
            responsive
            hover
            bordered
            v-bind:filter="searchText"
            sticky-header="300px"
            head-variant="dark"
          >
            <template v-slot:cell(updateButton)="row">
              <b-button
                variant="csub"
                size="sm"
                v-on:click.stop="openUpdateForm(row.item)"
              >
                編集
              </b-button>
            </template>
            <template v-slot:cell(deleteButton)="row">
              <b-button
                variant="outline-secondary"
                size="sm"
                v-on:click.stop="deleteUser(row.item)"
              >
                削除
              </b-button>
            </template>
          </b-table>
          <p v-if="getError" class="text-center text-caccent">
            ユーザ一覧の取得に失敗しました。
          </p>
          <p v-else-if="userList.length == 0" class="text-center text-csub">
            登録されているユーザがありません。
          </p>
        </b-card-body>
      </b-card>
    </section>
    <section id="add-update-user">
      <!-- ユーザ登録更新 -->
      <b-card no-body v-if="showForm">
        <b-card-body>
          <b-card-title title-tag="h2">
            <span v-if="isAdd">ユーザ新規登録</span>
            <span v-else>ユーザ編集</span>
          </b-card-title>
          <b-alert show v-if="message" v-bind:variant="alertColor">
            {{ message }}
          </b-alert>
          <b-form v-on:submit.prevent="onSubmitUser()">
            <b-form-row>
              <b-col md="6" lg="4">
                <base-input
                  v-model="userItems.username"
                  label="ユーザ名"
                  id="username"
                  v-bind:description="
                    isAdd
                      ? '半角英数字記号で入力ください'
                      : 'ユーザ名は変更できません'
                  "
                  v-bind:required="isAdd"
                  v-bind:disabled="!isAdd"
                >
                </base-input>
              </b-col>
              <b-col md="6" lg="4">
                <base-input
                  v-model="userItems.password"
                  label="パスワード"
                  id="password"
                  v-bind:placeholder="
                    !isAdd ? '変更する場合は入力してください' : ''
                  "
                  description="半角英数字記号8文字以上で入力ください"
                  v-bind:required="isAdd"
                >
                </base-input>
              </b-col>
            </b-form-row>
            <b-form-row>
              <b-col md="6" lg="4">
                <base-input
                  v-model="userItems.name"
                  label="名前"
                  id="name"
                  required
                >
                </base-input>
              </b-col>
            </b-form-row>
            <b-form-row>
              <b-col>
                <b-form-group>
                  <legend>
                    <b-badge variant="caccent">必須</b-badge>
                    権限
                  </legend>
                  <b-form-radio-group
                    id="role"
                    v-model="userItems.roleId"
                    v-bind:options="constants.userRole"
                    name="role"
                    class="box-large"
                    required
                  ></b-form-radio-group>
                </b-form-group>
              </b-col>
            </b-form-row>
            <b-form-row>
              <b-col lg="8">
                <b-form-group label="備考" label-for="memo">
                  <b-form-textarea
                    id="memo"
                    v-model="userItems.memo"
                    rows="5"
                  ></b-form-textarea>
                </b-form-group>
              </b-col>
            </b-form-row>
            <b-form-row>
              <b-col cols="6" sm="4" lg="2">
                <b-button
                  class="w-100 mb-3"
                  variant="caccent"
                  type="submit"
                  v-if="isAdd"
                >
                  登録
                </b-button>
                <b-button
                  class="w-100 mb-3"
                  variant="csub"
                  type="submit"
                  v-else
                >
                  更新
                </b-button>
              </b-col>
            </b-form-row>
          </b-form>
        </b-card-body>
      </b-card>
    </section>
  </b-container>
</template>

<script>
import BaseInput from '@/components/common/BaseInput'
import { convertTimezone } from '@/mixins/utility'
import textGettable from '@/mixins/textGettable'
export default {
  components: {
    BaseInput,
  },
  mixins: [convertTimezone, textGettable],
  data() {
    return {
      showForm: false,
      isAdd: true,
      // ***** ユーザ一覧 *****
      userList: [],
      userListFields: [
        { key: 'username', label: 'ユーザ名' },
        { key: 'name', label: '名前' },
        { key: 'roleText', label: '権限' },
        { key: 'updated', label: '更新日時', tdClass: 'text-center' },
        { key: 'created', label: '登録日時', tdClass: 'text-center' },
        { key: 'updateButton', label: '編集', tdClass: 'text-center' },
        { key: 'deleteButton', label: '削除', tdClass: 'text-center' },
        { key: 'memo', label: '備考' },
      ],
      searchText: null,
      getError: false,
      // ***** 入力form *****
      userItems: {
        id: null,
        username: null,
        password: null,
        name: null,
        roleId: null,
        memo: null,
      },
      alertColor: null,
      message: null,
    }
  },
  created() {
    this.getUsers()
  },
  methods: {
    getUsers() {
      // ユーザ一覧取得
      console.log('* get users *')
      this.getError = false
      this.$axiosToken
        .get('/api/users')
        .then((response) => {
          console.log(response)
          this.userList = response.data.userList
          for (let i = 0; i < this.userList.length; i++) {
            // 日付項目をLocalTimeに変換
            this.userList[i].updated = this.convertTimezone(
              this.userList[i].updated,
              false
            )
            this.userList[i].created = this.convertTimezone(
              this.userList[i].created,
              false
            )
            // codeを画面表示用テキストに変換
            this.userList[i].roleText = this.getText(
              'userRole',
              this.userList[i].roleId
            )
          }
        })
        .catch((error) => {
          console.log(error)
          this.getError = true
        })
    },
    openAddForm() {
      // 登録フォームを表示
      this.showForm = true
      this.isAdd = true
      this.message = null
      for (let k in this.userItems) {
        this.userItems[k] = null
      }
    },
    openUpdateForm(target) {
      // ユーザ情報をセットして更新フォーム表示
      console.log('*****', target.id)
      this.showForm = true
      this.isAdd = false
      this.message = null
      this.userItems.id = target.id
      this.userItems.username = target.username
      this.userItems.password = null
      this.userItems.name = target.name
      this.userItems.roleId = target.roleId
      this.userItems.memo = target.memo
    },
    onSubmitUser() {
      if (this.isAdd) {
        this.addUser()
      } else {
        this.updateUser()
      }
    },
    addUser() {
      // 新規ユーザ登録
      console.log('* add user *')
      this.message = null
      if (!this.checkString(this.userItems.username)) {
        alert('ユーザ名は半角英数字記号を使用してください。')
        return
      }
      if (
        !this.checkString(this.userItems.password) ||
        this.userItems.password.length < 8
      ) {
        alert('パスワードは8文字以上の半角英数字記号を使用してください。')
        return
      }
      this.$axiosToken
        .post('/api/users', {
          username: this.userItems.username,
          password: this.userItems.password,
          name: this.userItems.name,
          roleId: this.userItems.roleId,
          memo: this.userItems.memo,
        })
        .then((response) => {
          console.log(response)
          this.alertColor = 'csub'
          this.message =
            '新規ユーザとして登録が完了しました。' +
            this.userItems.username +
            ': ' +
            this.userItems.name
          for (let k in this.userItems) {
            this.userItems[k] = null
          }
          // 一覧更新
          this.getUsers()
        })
        .catch((error) => {
          console.log(error)
          this.alertColor = 'caccent'
          if (error.response) {
            console.log(error.response)
            this.message = 'エラー: ' + error.response.data.detail
          } else {
            this.message = 'エラー: 登録に失敗しました。'
          }
        })
    },
    updateUser() {
      // ユーザ更新
      console.log('* update user *')
      this.message = null
      if (this.userItems.password) {
        if (
          !this.checkString(this.userItems.password) ||
          this.userItems.password.length < 8
        ) {
          alert('パスワードは8文字以上の半角英数字記号を使用してください。')
          return
        }
      }
      this.$axiosToken
        .put('/api/users/' + this.userItems.id, {
          // username: this.userItems.username, //backendなしOKにしてもらう
          password: this.userItems.password, //backendなしOKにしてもらう
          name: this.userItems.name,
          roleId: this.userItems.roleId,
          memo: this.userItems.memo,
        })
        .then((response) => {
          console.log(response)
          this.alertColor = 'csub'
          this.message = 'ユーザ情報の更新が完了しました。'
          // 一覧更新
          this.getUsers()
        })
        .catch((error) => {
          console.log(error)
          this.alertColor = 'caccent'
          if (error.response) {
            console.log(error.response)
            this.message = 'エラー：' + error.response.data.detail
          } else {
            this.message = 'エラー：更新に失敗しました。'
          }
        })
    },
    deleteUser(target) {
      // ユーザ削除
      console.log('* delete user *', target.id)
      if (target.id == this.userItems.id) {
        // 編集選択されているものを削除する場合
        this.showForm = false
      }
      this.$axiosToken
        .delete('/api/users/' + target.id)
        .then((response) => {
          console.log(response)
          alert('削除されました。' + target.username + ': ' + target.name)
          // 一覧更新
          this.getUsers()
        })
        .catch((error) => {
          console.log(error)
          if (error.response) {
            console.log(error.response)
            alert('エラー：' + error.response.data.detail)
          } else {
            alert('エラー：削除に失敗しました。')
          }
        })
    },
    checkString(val) {
      // 半角英数記号チェック
      if (val.match(/^[a-zA-Z0-9!-/:-@¥[-`{-~]*$/)) {
        return true
      } else {
        return false
      }
    },
  },
}
</script>
